import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import AddAdminModal from "../components/AddAdminModal";
import Button from "../components/Button";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import Pagination from "../components/Pagination";
import Spinner from "../components/Spinner";
import { useAppSelector } from "../Redux/store";
import ApiManager from "../utils/ApiManager";
import { ADMIN_TYPES } from "../utils/Enums";
import FiltersContainer from "../components/FiltersContainer";

type Admin = {
  createdAt: string;
  fcm: string[];
  firstName: string;
  lastName: string;
  updatedAt: string;
  userType: string;
  username: string;
  _id: string;
};

const userTypeMap: {
  [key: string]: string;
} = {
  admin: "Admin",
  super_admin: "Super Admin",
};

const Admins = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [isDeletingAdmin, setIsDeletingAdmin] = useState(false);
  const [isAddAdminOpen, setIsAddAdminOpen] = useState(false);

  const handleGetAdmins = async () => {
    setIsFetching(true);
    const res = await new ApiManager().getAdmins({
      page: currentPage,
      limit: 10,
      search: searchValue,
    });
    if (res.success) {
      setAdmins(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setIsFetching(false);
  };

  const searchRef = useRef<HTMLInputElement>(null);

  const user = useAppSelector((state) => state.auth.user);

  const isAdmin = user?.userType === ADMIN_TYPES.ADMIN;

  const handleDeleteAdmin = async () => {
    setIsDeletingAdmin(true);
    const res: any = await new ApiManager().deleteAdmin(selectedAdminId);
    if (res?.success) {
      toast(res?.data?.message, { type: "success" });
      const updatedAdmins = admins?.filter(
        (admin: Admin) => admin._id !== selectedAdminId
      );
      handleGetAdmins();
      if (updatedAdmins?.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
    } else {
      toast(res?.error?.data?.error, { type: "error" });
    }
    setSelectedAdminId("");
    setIsDeletingAdmin(false);
  };

  useEffect(() => {
    handleGetAdmins();
  }, [currentPage, searchValue]);

  return (
    <>
      <Helmet>
        <title>Admins</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="pb-4 flex flex-col justify-center font-inter bg-gray-100 h-screen">
          <FiltersContainer>
            <div className="flex items-center justify-end my-4">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setCurrentPage(1);
                  if (search) setSearchValue(search);
                }}
              >
                <input
                  ref={searchRef}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  className="w-52 ml-4 px-4 h-12 outline-none rounded "
                  placeholder="search"
                />
                <Button
                  disabled={search === ""}
                  type="submit"
                  className="w-32 h-10 ml-4 rounded bg-primary text-white"
                >
                  Search
                </Button>
                <Button
                  disabled={search === ""}
                  onClick={() => {
                    setSearch("");
                    setSearchValue("");
                  }}
                  type="button"
                  className="btn-outline w-20 h-10 ml-4 border border-primary text-primary rounded"
                >
                  Clear
                </Button>
              </form>
              {/* <Link to="/dashboard/admins/add"> */}
              <Button
                onClick={() => setIsAddAdminOpen(true)}
                className="btn-outline ml-4 border border-primary text-primary  w-32 py-2 rounded"
              >
                Add Admin
              </Button>
              {/* </Link> */}
            </div>
          </FiltersContainer>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isFetching ? (
              <Spinner />
            ) : admins.length === 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} alt="Empty Image" />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Admins Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>First Name</TH>
                    <TH>Last Name</TH>
                    <TH>Email</TH>
                    <TH>User Type</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {admins?.map((admin: Admin, index: number) => (
                    <TR key={index}>
                      <TH>{admin?.firstName || "N/A"}</TH>
                      <TH>{admin?.lastName || "N/A"}</TH>
                      <TH>{admin?.username || "N/A"}</TH>
                      <TH>{userTypeMap[admin?.userType] || "N/A"}</TH>
                      {isAdmin && admin.userType === ADMIN_TYPES.SUPER_ADMIN ? (
                        <TH>N/A</TH>
                      ) : user?._id === admin?._id ? (
                        <TH>N/A</TH>
                      ) : (
                        <TH>
                          <button
                            onClick={() => setSelectedAdminId(admin._id)}
                            className="text-red-500 m-0 p-0 font-semibold"
                          >
                            Delete
                          </button>
                        </TH>
                      )}
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              currentPage={currentPage}
              page={totalPages}
              handlePageClick={(e: { selected: number }) =>
                setCurrentPage(e.selected + 1)
              }
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        handleClick={handleDeleteAdmin}
        isSubmitButtonDisabled={isDeletingAdmin}
        isSubmitting={isDeletingAdmin}
        isVisible={selectedAdminId !== ""}
        onHide={() => setSelectedAdminId("")}
        text="Are you sure you want to delete this admin?"
      />
      <AddAdminModal
        onSuccess={handleGetAdmins}
        isVisible={isAddAdminOpen}
        onHide={() => setIsAddAdminOpen(false)}
      />
    </>
  );
};

export default Admins;
