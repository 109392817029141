import { Dialog, Transition } from "@headlessui/react";
import React, { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import { instance } from "../utils/ApiManager";
import DataTable, { THead, TR, TH, TBody } from "./DataTable";
import { UserType } from "../pages/DashboardUsers";
import Pagination from "./Pagination";
import Spinner from "./Spinner";
import { DriverType } from "../pages/DashboardDrivers";

type PropTypes = {
  isOpen: boolean;
  closeModal: () => void;
  userType: string;
  addToTarget: (id: string) => void;
  removeFromTarget: (id: string) => void;
  targets: string[];
};

type ChildTableType = Pick<PropTypes, "addToTarget"> &
  Pick<PropTypes, "removeFromTarget"> &
  Pick<PropTypes, "targets">;

const SpecificTable = ({
  isOpen,
  closeModal,
  userType,
  addToTarget,
  removeFromTarget,
  targets,
}: PropTypes) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  Select {userType}
                </Dialog.Title>
                {userType === "user" ? (
                  <UsersTable
                    targets={targets}
                    removeFromTarget={removeFromTarget}
                    addToTarget={addToTarget}
                  />
                ) : (
                  <DriversTable
                    targets={targets}
                    removeFromTarget={removeFromTarget}
                    addToTarget={addToTarget}
                  />
                )}
                <div className="flex justify-end">
                  <button
                    onClick={closeModal}
                    className="bg-primary text-white rounded px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SpecificTable;

const UsersTable = ({
  addToTarget,
  removeFromTarget,
  targets,
}: ChildTableType) => {
  const [users, setUsers] = useState<UserType[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const searchRef = useRef("");

  const getUsers = async () => {
    setLoading(true);
    const res = await instance.get("/admin/user/all", {
      params: { limit: 10, page: currentPage, search, searchKey: "fullName" },
    });
    if (res.status === 200) {
      setUsers(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setLoading(false);
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    setSearch(searchRef.current);
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, search]);

  return (
    <div>
      <form onSubmit={handleSearch} className="mb-4">
        <input
          onChange={(e) => (searchRef.current = e.target.value)}
          className="w-52 px-4 h-10 outline-none rounded border border-gray-200"
          placeholder="search"
        />
        <button
          type="submit"
          className="w-32 h-10 rounded bg-primary text-white"
        >
          Search
        </button>
      </form>
      {loading ? (
        <Spinner />
      ) : !loading && users.length === 0 ? (
        <p>No user's found </p>
      ) : (
        <DataTable>
          <THead>
            <TR>
              <TH></TH>
              <TH>Username</TH>
              <TH>Email</TH>
              <TH>Phone</TH>
              <TH>Created At</TH>
            </TR>
          </THead>
          <TBody>
            {users?.map((user) => (
              <TR key={user?._id}>
                <TH>
                  <input
                    checked={targets.includes(user?._id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addToTarget(user?._id);
                      } else {
                        removeFromTarget(user?._id);
                      }
                    }}
                    type="checkbox"
                  />
                </TH>
                <TH className="flex items-center ">
                  <p className="ml-2 truncate text-md text-black font-normal">
                    {user.fullName}
                  </p>
                </TH>
                <TH className="relative group">
                  <p className="w-56 2xl:w-72 flex text-black font-normal flex-shrink-0 truncate mr-2 text-md  ">
                    {user.email}
                  </p>
                </TH>
                <TH className="text-black font-normal">{user.phone}</TH>
                <TH className="font-normal text-black">
                  {user?.createdAt?.slice(0, 10)}
                </TH>
              </TR>
            ))}
          </TBody>
        </DataTable>
      )}
      <div className="mt-4">
        <Pagination
          page={totalPages}
          handlePageClick={(e: { selected: number }) =>
            setCurrentPage(e.selected + 1)
          }
        />
      </div>
    </div>
  );
};

const DriversTable = ({
  addToTarget,
  removeFromTarget,
  targets,
}: ChildTableType) => {
  const [drivers, setDrivers] = useState<DriverType[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLaoding] = useState(false);

  const searchRef = useRef("");

  const getDrivers = async () => {
    setLaoding(true);
    const res = await instance.get("/admin/driver/all", {
      params: { limit: 10, page: currentPage, search },
    });
    if (res.status === 200) {
      setDrivers(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setLaoding(false);
  };

  const handleSearch = (event: FormEvent) => {
    event.preventDefault();
    setSearch(searchRef.current);
  };

  useEffect(() => {
    getDrivers();
  }, [currentPage, search]);

  return (
    <div>
      <form onSubmit={handleSearch} className="mb-4">
        <input
          onChange={(e) => (searchRef.current = e.target.value)}
          className="w-52 px-4 h-10 outline-none rounded border border-gray-200"
          placeholder="search"
        />
        <button
          type="submit"
          className="w-32 h-10 rounded bg-primary text-white"
        >
          Search
        </button>
      </form>
      {loading ? (
        <Spinner />
      ) : !loading && drivers.length === 0 ? (
        <p>No driver's found </p>
      ) : (
        <DataTable>
          <THead>
            <TR>
              <TH></TH>
              <TH>Username</TH>
              <TH>Email</TH>
              <TH>Phone</TH>
              <TH>Created At</TH>
            </TR>
          </THead>
          <TBody>
            {drivers?.map((driver) => (
              <TR key={driver?._id}>
                <TH>
                  <input
                    checked={targets.includes(driver?._id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addToTarget(driver?._id);
                      } else {
                        removeFromTarget(driver?._id);
                      }
                    }}
                    type="checkbox"
                  />
                </TH>
                <TH className="flex items-center ">
                  <p className="ml-2 truncate text-md text-black font-normal">
                    {driver.fullName}
                  </p>
                </TH>
                <TH className="relative group">
                  <p className="w-56 2xl:w-72 flex text-black font-normal flex-shrink-0 truncate mr-2 text-md  ">
                    {driver.email}
                  </p>
                </TH>
                <TH className="text-black font-normal">{driver.phone}</TH>
                <TH className="font-normal text-black">
                  {driver?.createdAt?.slice(0, 10)}
                </TH>
              </TR>
            ))}
          </TBody>
        </DataTable>
      )}
      <div className="mt-4">
        <Pagination
          page={totalPages}
          handlePageClick={(e: { selected: number }) =>
            setCurrentPage(e.selected + 1)
          }
        />
      </div>
    </div>
  );
};
